$White1: #f1f1f1;
$PureWhite: #fff;
$Orange: #e26751;
$Navy: #002d72;
$Charcoal: #293138;
$Alto: #D3D3D3;
$Shark: #202225;
$SharkContrast: #989898;
$Sinbad: #9EC4D5;
$Purple: #8f3e8f;
$Yellow: yellow;