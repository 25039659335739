.section {
    min-height: 5rem;
    
    &.section--drop-zone {
        & > .section__row {
            & > .section__player-list {
                background-color: lighten($SectionBackgroundBrush, 10%);
            }
        }
    }

    & > .section__row {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 10px;

        & > .section__row-icon {
            grid-column: 1;

            width: 2rem;
            height: 2rem;
            background-color: $SectionIconBackgroundBrush;
            border-radius: 50%;
        }

        & > .section__player-list {
            grid-column: 2;

            display: flex;
            flex-direction: row;
            flex-flow: wrap;
            background-color: $SectionBackgroundBrush;
            border-radius: 5px;
            gap: 1rem;
            padding: 1rem;
        }
    }
}