.player {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto;
    align-items: center;
    gap: 0.5rem;
    min-height: 3.5rem;
    border: 1.5px solid $PlayerBorderBrush;
    border-radius: $standard-border-radius;
    padding: 0.10rem 0.5rem;
    box-shadow: 0px 1px 30px 2px rgba(0,0,0,0.50);
    max-width: 15rem;

    &.player--black {
        background-color: #808080;
    }

    &.player--blue {
        background-color: #87cefa;
    }

    &.player--brown {
        background-color: #a52a2a;
    }

    &.player--cyan {
        background-color: #00ffff;
    }

    &.player--green {
        background-color: #90ee90;
    }

    &.player--lime {
        background-color: #00ff00;
    }

    &.player--orange {
        background-color: #ffa500;
    }

    &.player--pink {
        background-color: #ffc0cb;
    }

    &.player--purple {
        background-color: #8f3e8f;
    }

    &.player--red {
        background-color: #ff0000;
    }

    &.player--white {
        background-color: #ffffff;
    }

    &.player--yellow {
        background-color: #ffff00;
    }

    &.player--banana {
        background-color: #ffff80;
    }

    &.player--coral {
        background-color: #ffe6eb;
    }

    &.player--grey {
        background-color: #a6a6a6;
    }

    &.player--maroon {
        background-color: #d65f5f;
    }

    &.player--rose {
        background-color: #fff3f5;
    }

    &.player--tan {
        background-color: #e0ccb1;
    }

    & > .player__icon {
        width: 2.25rem;
        height: 2.75rem;
    }

    & > .player__name {
        @extend %truncated-text;
        color: $PlayerBrush;
        font-weight: bold;
    }

    & > .player__nav {
        display: grid;
        gap: 0.5rem;

        & > .player__nav-button {
            // TODO: style states

            all: unset;

            &:disabled {
                all: unset;
            }

            &:hover {
                all: unset;
            }

            // TODO: make focus a mixin
            &:focus { 
                outline: 3px solid $DarkFocusBrush; 
                outline-offset: 3px;
            }

            &:focus:not(:focus-visible) {
              /* Undo all the above focused button styles
                 if the button has focus but the browser wouldn't normally
                 show default focus styles */
                 outline: unset;
                 outline-offset: unset;
            }
            
            &:focus-visible { 
                outline: 3px solid $DarkFocusBrush; 
                outline-offset: 3px;
            }
    
            & > .player__nav-button-icon {
                width: 16px;
                height: 16px;
            }
        }
    }
}