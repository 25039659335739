.app {
  background: $ApplicationBackgroundBrush;

  & > .app__inner {
    box-shadow: 0px 1px 30px 2px rgba(0, 0, 0, 1.05);
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto auto 1fr;
    max-width: 1280px;
    background: $LayoutBackgroundBrush;
    margin: 0 auto;

    & > .app__header {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      text-align: center;

      & > .app__source-code {
        padding: 1rem;
      }

      @media only screen and (max-width: $tablet-breakpoint) {
        display: none;
      }
    }

    & > .app__mobile-information {
      display: none;
      text-align: center;
      margin: 1em;

      @media only screen and (max-width: $tablet-breakpoint) {
        display: initial;
      }
    }

    & > .app__information {
      text-align: center;
      margin-bottom: 0.5rem;

      @media only screen and (max-width: $tablet-breakpoint) {
        display: none;
      }
    }

    & > .app__body {
      display: grid;
      grid-template-columns: 2fr 1fr;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 2.5rem;
      padding-top: 0;
      gap: 3rem;

      @media only screen and (max-width: $tablet-breakpoint) {
        grid-template-columns: 1fr;
      }
    }
  }
}